import Header from './Header/Header.js'
import Home from './HomeView/Home.js'
import PrivacyPolicy from './PrivacyPolicyView/PrivacyPolicy.js'
import WhyVisionGPT from './WhyVisionGPTView/WhyVisionGPT.js'
import ContactUs from './ContactUsView/ContactUs.js'

import './App.css'

import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';


function App() { 
  return (
    <Router>
    <Header className='header'/>
    <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy/>} />
        <Route path='/WhyVisionGPT' element={<WhyVisionGPT/>} />
        <Route path='/ContactUs' element={<ContactUs/>} />
        <Route path='*'element={<Home />} />
    </Routes> 
    </Router>
  );

}

export default App;