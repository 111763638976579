
import './Header.css';
import logo from './VisionGPTLogo.png';
import React, {useEffect, useState} from 'react';

import CustomHeaderButton from './CustomHeaderButton.js'

import Dropdown from 'react-bootstrap/Dropdown'

import dropdownImg from './dropdownImage.png'


import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/esm/Button';

  
const Header = () => {
    let navigate = useNavigate();
    
    const homeRoute = () => { 
      window.scrollTo({top:0})
      let path = `/`; 
      navigate(path);
    }

    const whyVisionGPTRoute = () => { 
      window.scrollTo({top:0})
      let path = `/WhyVisionGPT`; 
      navigate(path);
    }

    const privacyPolicyRoute = () => { 
      window.scrollTo({top:0})
        let path = `/PrivacyPolicy`; 
        navigate(path);
      }

    const contactUsRoute = () => {
      window.scrollTo({top:0})
      let path = '/ContactUs'
      navigate(path)
    }

    const [isMobile,setIsMobile] = useState(false)

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 1085)
      }

      handleResize()

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    },[])


    const handleInstallClick =() => {
      const user = navigator.userAgent || navigator.vendor || window.opera

      const isApple = /iPad|iPhone|iPod/.test(user) && !window.MSStream;
      const isAndroid = /android/i.test(user)

      if (isApple) {
        window.location.href = 'https://apps.apple.com/us/app/visiongpt-powered-by-gpt-3-5/id6446451225'
      }
      else if (isAndroid) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.VisionGPT.visiongpt'
      }
      else {
        alert('VisionGPT is not supported on your device.')
      }
    }
      
    return (

      <div >
        {isMobile ? (
          <div className='header'>
            
            <div style={{display: 'flex', flex: '1'}}>
            
              <Dropdown>
                <Dropdown.Toggle className ='header-button' variant='custom'>
                <img src={dropdownImg} className = 'header-button-image' />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={homeRoute}>Home</Dropdown.Item>
                  <Dropdown.Item onClick={whyVisionGPTRoute}>Why VisionGPT?</Dropdown.Item>
                  <Dropdown.Item onClick={privacyPolicyRoute}>Privacy Policy</Dropdown.Item>
                  <Dropdown.Item onClick={contactUsRoute}>Contact Us</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            
            <div style={{display: 'flex', flex: '1', justifyContent: 'center'}}>
              <img src={logo} className = 'logo' />
            </div>

            <div style={{display: 'flex', flex: '1', justifyContent: 'flex-end'}}>
              <Button className='install-button' variant='custom' onClick={handleInstallClick}>Install</Button>
            </div>
           
          </div>        
        ) : 
        (
          <div className='header'>

            <div className='header-div-1'>
              <img src={logo} className = 'logo' />
                
                <h1 style ={{paddingLeft: '1%', paddingRight: '2%'}}>VisionGPT</h1>
          
                <div>
                    <CustomHeaderButton text='Home' onClick={homeRoute}/>
                    <CustomHeaderButton text='Why VisionGPT?' onClick={whyVisionGPTRoute}/>
                    <CustomHeaderButton text='Privacy Policy' onClick={privacyPolicyRoute}/>
                    <CustomHeaderButton text='Contact Us' onClick={contactUsRoute}/>
                  
                </div>
            </div>

            <div className='header-div-2'>

              <a href='https://play.google.com/store/apps/details?id=com.VisionGPT.visiongpt&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className='google-button'/>
              </a>

              <a href="https://apps.apple.com/us/app/visiongpt-powered-by-gpt-3-5/id6446451225?itsct=apps_box_badge&amp;itscg=30200" >
                <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1679875200" alt="Download on the App Store" className='apple-button'/>
              </a>
            </div>
            
          </div>
          )
        }
      </div>

    )

    // return(
    //     <div className='header'>

    //         <div className='header-div-1'>
    //             <img src={logo} className = 'logo' />
                
    //             <h1 style ={{paddingLeft: '1%', paddingRight: '2%'}}>VisionGPT</h1>
          
    //             <div>
    //                 <CustomHeaderButton text='Home' onClick={homeRoute}/>
    //                 <CustomHeaderButton text='Why VisionGPT?' onClick={whyVisionGPTRoute}/>
    //                 <CustomHeaderButton text='Privacy Policy' onClick={privacyPolicyRoute}/>
    //                 <CustomHeaderButton text='Contact Us' onClick={contactUsRoute}/>
                  
    //             </div>
    //         </div>

    //         <div className='header-div-2'>

    //           <a href='https://play.google.com/store/apps/details?id=com.VisionGPT.visiongpt&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
    //             <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className='google-button'/>
    //           </a>

    //           <a href="https://apps.apple.com/us/app/visiongpt-powered-by-gpt-3-5/id6446451225?itsct=apps_box_badge&amp;itscg=30200" >
    //             <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1679875200" alt="Download on the App Store" className='apple-button'/>
    //           </a>
    //         </div>
            
    //   </div>
    // )
}

export default Header;