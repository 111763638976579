import './HomeView.css';

import appleScreenshot1 from './appleScreenshots/screenshot1 1.png'
import appleScreenshot2 from './appleScreenshots/screenshot2 1.png'
import appleScreenshot3 from './appleScreenshots/screenshot3.png'
import appleScreenshot4 from './appleScreenshots/screenshot4.png'
import appleScreenshot5 from './appleScreenshots/screenshot5.png'

import androidScreenshot1 from './androidScreenshots/android1.png'
import androidScreenshot2 from './androidScreenshots/android2.png'
import androidScreenshot3 from './androidScreenshots/android3.png'
import androidScreenshot4 from './androidScreenshots/android4.png'


const Home = () => {

    return ( 
        
        <div className='body'>

            <div style={{display:'flex', flex: 1, flexDirection: 'column', alignItems: 'center', height: '80%'}}>
                
              <h1 style={{color:'black'}}>Introducing VisionGPT</h1> 

              <iframe 
                style={{width: '100vw', height: '56.25vw', maxWidth: '177.78vh', maxHeight: '80vh'}}
                src="https://www.youtube.com/embed/ESOH41mtZ3s"
                title="YouTube video player" 
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowfullscreen>

              </iframe>
            
            </div>

            <br/>

            <div>
              <h1 style ={{paddingLeft: '2%'}}>Apple</h1>

              <div style ={{height: '2px', width: '98%', backgroundColor: '#000000', marginLeft: '1%', marginRight: '1%'}}></div>

              <br/>

              <div className='scrollable-images-div'>
                
                <div className='screenshot-div'>
              
                  <img src={appleScreenshot1} className='screenshots' />
                 
                  <h4 style = {{textAlign: 'center'}}>Scan your questions with your camera!</h4>
                </div>

                <div className='screenshot-div'>
                  <img src={appleScreenshot2} className='screenshots' />
                  <h4 style = {{textAlign: 'center'}}>Generate AI answers to your questions!</h4>
                </div>

                <div className='screenshot-div'>
                  <img src={appleScreenshot3} className='screenshots' />
                  <h4 style = {{textAlign: 'center'}}>Generate web search results to your questions!</h4>
                </div>

                <div className='screenshot-div'>
                  <img src={appleScreenshot4} className='screenshots' />
                  <h4 style = {{textAlign: 'center'}}>Siri integration allows you to talk to VisionGPT by saying "Hey Siri, Ask Vision"!</h4>
                </div>


                <div className='screenshot-div'>
                  <img src={appleScreenshot5} className='screenshots' />
                  <h4 style = {{textAlign: 'center'}}>Share VisionGPT's responses with your friends and family or even other devices!</h4>
                </div>

              </div>
              
            </div>

            <br/>

            <div>
              <h1 style ={{paddingLeft: '2%'}}>Android</h1>

              <div style ={{height: '2px', width: '98%', backgroundColor: '#000000', marginLeft: '1%', marginRight: '1%'}}></div>

              <br/> 

              <div className='scrollable-images-div'>

              <div className='screenshot-div'>
                <img src={androidScreenshot1} className='screenshots' />
                  <h4 style = {{textAlign: 'center'}}>Ask VisionGPT for recommendations, explanations, or any other question! </h4>
                </div>

                <div className='screenshot-div'>
                  <img src={androidScreenshot2} className='screenshots' />
                  <h4 style = {{textAlign: 'center'}}>Scan your questions into the app!</h4>
                </div>

                <div className='screenshot-div'>
                  <img src={androidScreenshot3} className='screenshots' />
                  <h4 style = {{textAlign: 'center'}}>Generate AI responses to scanned questions or text!</h4>
                </div>

                <div className='screenshot-div'>
                  <img src={androidScreenshot4} className='screenshots' />
                  <h4 style = {{textAlign: 'center'}}>Share VisionGPT's responses with your friends and family or even other devices!</h4>
                </div>

              </div>
            
            </div>
            
      

            {/* <br/>

            <div style={{display:'flex', flex: 1, alignItems: 'center'}}>
                <div style={{display:'flex', flex: 1, justifyContent: 'end'}}>
                <h1 className = 'display-linebreak' style={{color:'white', textAlign: 'center',fontSize: 50, fontWeight: 'normal'}}>{screenshot1Text}</h1>
                </div>
                
                <div style={{display:'flex', flex: 1, justifyContent: 'center'}}>
                <img className='screenshots' src = {screenshot1}/>
                </div>
                
                
            </div>
        <br/>

        <div style={{display:'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <div style={{display:'flex', flex: 1, justifyContent: 'end'}}>
            <h1 className = 'display-linebreak' style={{color:'white', textAlign: 'center',fontSize: 50, fontWeight: 'normal'}}>{screenshot2Text}</h1>\
           </div>

           <div style={{display:'flex', flex: 1, justifyContent: 'center'}}>
            <img className='screenshots' src = {screenshot2}/>
            </div>
        </div>
        <br/>

        <div style={{display:'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <div style={{display:'flex', flex: 1, justifyContent: 'end'}}>
            <h1 className = 'display-linebreak' style={{color:'white', textAlign: 'center',fontSize: 50, fontWeight: 'normal'}}>{screenshot3Text}</h1>
          </div>
          <div style={{display:'flex', flex: 1, justifyContent: 'center'}}>
            <img className='screenshots' src = {screenshot3}/>
          </div>
        </div>
        <br/>

        <div style={{display:'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <div style={{display:'flex', flex: 1, justifyContent: 'end'}}>
            <h1 className = 'display-linebreak' style={{color:'white', textAlign: 'center',fontSize: 50, fontWeight: 'normal',}}>{screenshot4Text}</h1>
          </div>
        
          <div style={{display:'flex', flex: 1, justifyContent: 'center'}}>
            <img className='screenshots' src = {screenshot4}/>
          </div>
        </div>
        <br/>  */}

      </div>
    );
  };
    
  export default Home;