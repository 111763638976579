
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className='page'>
            <h1 className='privacy-title'>Privacy Policy</h1>
            <br/>
            <h3 className='privacy-sub-heading'>
                VisionGPT Privacy Policy describes how VisionGPT collects, uses and shares your personal data.
            </h3>
            <h4 className='privacy-sub-heading'>  
                Updated March 19, 2023
            </h4>
            <br/>
            <h5 className='privacy-body'>
                At VisionGPT, we believe in your right to privacy. This means that we strive to collect only the personal data that we need to provide the best services VisionGPT has to offer. 
                When you use VisionGPT we do collect information related to your usage within the app. This information is primarily used for user analytics and usage within the app.
                Moreover, the chat messages entered within the chat are shared with third parties. These parties include Google and OpenAI. 
                They potentially may collect information on the data sent as messages within the app.
            </h5>
            <br/>
            <h5 className='privacy-body'>
            You are not required to provide the personal data that we have requested.
            However, if you choose not to do so, we will not be able to provide you with our products or services or respond to requests you may have.
            </h5>
            <br/>
            <h5 className='privacy-body'>
            VisionGPT only shares your personal data with third parties Google and OpenAI.
            VisionGPT may share personal data with service providers who act on our behalf or government agencies as required by law.
            </h5>
        </div>  
        
    )
}

export default PrivacyPolicy;