
import React from 'react'

const ImageLinkButton = ({imageURL, linkURL, text}) => {
    
    const handleClick = () => {
        window.open(linkURL, '_blank');
    }

    return(
        <a href={linkURL} target='_blank' rel='noopener noreferrer'>
            <img style={{width: '15%', padding: '1%', minWidth: '150px'}} src={imageURL} alt={text} onClick={handleClick}/>
        </a>
    )
}


export default ImageLinkButton