import './WhyVisionGPT.css'


const WhyVisionGPT =  () => {
    return(
        <div className='page'>
            <h1 className='why-title'>Why VisionGPT?</h1>

            <br/>

            <h2 className='why-sub-heading'>With all the craze surrounding artificial intelligence and multiple apps and products being available, why choose VisionGPT?</h2>

            <br/>

            <h4 className='why-body'>
                VisionGPT is an easy-to-use app that allows you to interact with artificial intelligence with no limits.
                 Some apps restrict the usage of their app to force you to pay for subscriptions and other in-app purchases. 
                VisionGPT strives to provide free access to artificial intelligence so that everyone can benefit from the power of AI. 
                This means you can send an endless amount of questions to VisionGPT without limits or purchasing anything.
            </h4>

            <h4 className='why-body'>
                VisionGPT provides web results alongside AI-generated responses. 
                While GPT-3.5 (the AI engine that powers VisionGPT and OpenAI’s ChatGPT) is extremely powerful, AI, at times, still provides faulty information. 
                To combat this negative experience, we allow you to generate search results from the web. 
                This way, you are getting the most information provided to you so that you can answer your questions. 
                This is our solution to combat faulty information so that you are not misinformed.
            </h4>

            <h4 className='why-body'>
                VisionGPT allows you to scan questions and text directly into the app. 
                Skip the hassle of typing long questions or paragraphs. 
                You can scan specific questions from a page full of text, or scan the entire page of text to generate a response from VisionGPT. 
                This allows you to be more productive by saving a lot of time that would be spent either typing or figuring out how to copy text into the app.
            </h4>

            <h4 className='why-body'>
                VisionGPT provides multiple accessibility and quality-of-life features. 
                VisionGPT offers a hands-free experience that you can access by communicating with Siri. 
                You can use shortcuts and phrases, such as "Ask Vision", to have Siri ask VisionGPT questions and read out the responses. 
                Moreover, you can easily share VisionGPT’s responses with all your friends and family. 
                Share the interesting and entertaining responses that you generate from VisionGPT with the tap of a single button.
            </h4>
        </div>
    )
}


export default WhyVisionGPT