import React, {useState} from 'react'

import './Header.css'

function CustomHeaderButton({text, onClick}) {

    const [isHovered, setIsHovered] = useState(false)

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseExit = () => {
        setIsHovered(false)
    }

    const buttonStyle = {
        backgroundColor: isHovered ? 'lightgray': 'white',
        transition: 'background-color 0.3s ease'
    }

    return (
        <button className='header-button'
        style={buttonStyle}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseExit}
        >{text}</button>
    )
}

export default CustomHeaderButton