import './ContactUs.css'
import ImageLinkButton from './ImageLinkButton.js'

import youtubeImg from './youtubescreenshot.png'
import twitterImg from './twitterscreenshot.png'
import tiktokImg from './tiktokscreenshot.png'
import instaImg from './instagramscreenshot.png'



const ContactUs = () => {

    const businessEmail = `mailto:visiongpt@gmail.com?subject=${encodeURIComponent('Business Inquiry')}`

    const suggestionsEmail = `mailto:visiongpt@gmail.com?subject=${encodeURIComponent('VisionGPT Suggestion')}`

    return(

        <div className='page'>
            <h1 className='contact-title'>Contact Information</h1>

            <br/>

            <div>
                <h3 className='sub-heading-text'>Social Media</h3>
                <h4 className='sub-heading-text'> Visit our social media accounts to see whats new with VisionGPT!</h4>

                <ImageLinkButton className='image-link-button' linkURL={'https://www.youtube.com/channel/UCviW-tIGexdXnWo43_zEqQg'} imageURL={youtubeImg} Text={'Youtube'}/>
                <ImageLinkButton linkURL={'https://twitter.com/VisionGPT'} imageURL={twitterImg} Text={'Twitter'}/>
                <ImageLinkButton linkURL={'https://www.tiktok.com/@visiongpt'} imageURL={tiktokImg} Text={'TikTok'}/>
                <ImageLinkButton linkURL={'https://www.instagram.com/visiongpt/?next=%2F'} imageURL={instaImg} Text={'Instagram'}/>
            </div>

            <br/>

            <div>
                <h3 className='sub-heading-text'>Business Inquiries</h3>
                <div style={{flex: '1', flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                    <h4 className='sub-heading-text'>For business related inquires, please reach out by sending any emails to <a href={businessEmail}>visiongpt@gmail.com</a></h4>
                    
                </div>
            </div>


            <br/>


            <div>
                <h3 className='sub-heading-text'>Suggestions</h3>
                <div style={{flex: '1', flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                    <h4 className='sub-heading-text'>For any suggestions relating to VisionGPT, please send the suggestions via email to <a href={suggestionsEmail}>visiongpt@gmail.com</a></h4>
                    
                </div>
            </div>
        </div>
        
        
    )
}

export default ContactUs